import { configuration } from '../../configuration';
import { Account } from '../../data/account';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class AccountService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'accounts');
  }

  public async getAccountByName(name: string): Promise<Account | undefined> {
    const account = await this.apiClient.get<Account>(this.url(name));
    if (!account) {
      return;
    }
    account.avatar = configuration.backendApi.avatarUrl + account.avatar;
    return account;
  }
}
export const accountService = new AccountService(new BearerTokenApiClient());
