import { Flex, ScrollArea, Title } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ServiceAccountLinked } from '../../../data/account';
import { ForgeTable, Row } from '../../Table/ForgeTable';

export interface SocialsProps {
  isLoading?: boolean;
  services?: ServiceAccountLinked[];
}

const SocialsTable: FunctionComponent<SocialsProps> = props => {
  const headers = ['Accounts', 'Account Name', 'Link'];

  const getAccountByName = (service: string): string => {
    const elements = service?.split('/');
    const name = elements[elements?.length - 1];
    return ['null', ''].includes(name) ? '' : name;
  };

  const getAccountLink = (service: string) => {
    const elements = service?.split('/');
    if (!elements[0]) {
      return '';
    }
    return <Link to={service}>{service}</Link>;
  };
  const getRows = (): Row[] => {
    if (!props.services || props.services.length === 0) {
      return [];
    }
    return props.services.map(service => {
      return {
        elements: [
          service.name,
          getAccountByName(service.value),
          getAccountLink(service.value),
        ],
      };
    });
  };

  return (
    <Flex direction={'column'}>
      <Title size={'s'}>Linked Accounts</Title>
      <ScrollArea>
        <ForgeTable
          headers={headers}
          rows={getRows()}
          isLoading={props.isLoading}
        />
      </ScrollArea>
    </Flex>
  );
};

export default SocialsTable;
