import { Button, Flex } from '@mantine/core';
import { useState } from 'react';
import KolInviteList from '../../components/KolInviteList/KolInviteList';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useDialog } from '../../hooks/use-dialog';
import { kolService } from '../../services/api-client/kol-service';
import { MessageStatus } from '../../services/dialog';
import { withAuthProtection } from '../../services/protect-route-element';

const Page = () => {
  const notification = useDialog();
  const [addingInvite, setAddingInvite] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [kolInvites, kolInvitesLoading] = useDataFetcher({
    serviceCall() {
      return kolService.getKolInvites();
    },
    dependencies: [refresh],
    onError() {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Failed to fetch kol invites'
      );
    },
  });

  const handleNewInvite = async () => {
    try {
      setAddingInvite(true);
      await kolService.addNewInvite();
      setRefresh(!refresh);
    } catch (error) {
      notification.notify(
        MessageStatus.Error,
        'Error',
        'Failed to create new invite'
      );
    } finally {
      setAddingInvite(false);
    }
  };

  const getInvites = () => {
    if (!kolInvites) {
      return [];
    }
    return [...kolInvites].reverse();
  };

  return (
    <Flex
      p={'1rem'}
      direction={'column'}
      gap={'sm'}
      className="KolInvitesPageWrapper"
    >
      <Flex w={'100%'} direction={'row'} justify={'end'}>
        <Button
          loading={addingInvite || kolInvitesLoading}
          color="blue"
          onClick={() => handleNewInvite()}
        >
          Create Invite
        </Button>
      </Flex>
      <KolInviteList
        invites={getInvites()}
        isLoading={kolInvitesLoading}
      ></KolInviteList>
    </Flex>
  );
};

export const KolInvites = withAuthProtection(Page);
