import { Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../hooks/use-dialog';
import { authentication } from '../../services/authentication';
import { MessageStatus } from '../../services/dialog';

const Page = () => {
  const navigate = useNavigate();
  const notifications = useDialog();
  useEffect(() => {
    const completeLogin = async () => {
      try {
        await authentication.handleRedirectCallback();
        const user = authentication.getUser();
        if (!user?.email?.endsWith('@forge.gg')) {
          notifications.notify(
            MessageStatus.Error,
            'Login error. ',
            'Invalid account credentials. '
          );
          authentication.logout();
        }
        navigate('/home');
      } catch (err: any) {
        console.error(err);
      }
    };
    completeLogin();
  }, [navigate]);
  return (
    <div>
      <Loader size="xl" />
    </div>
  );
};

export const LoginCallbackPage = Page;
